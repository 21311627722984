export const STATIC_DOCUMENTATION_IDS = {
  FLEX_TROUBLESHOOTING_GUIDE: '89d7456f-2c16-45c2-9985-bf860704c367',
  TEST_POINT_INSTALLATION_INSTRUCTIONS: '432a75ec-631d-4160-b8cf-22f725ca08d8',
  WHITE_LISTING_GUIDANCE: 'b923c131-99af-459b-b340-e89c83d2df27',
  EMAIL_RETRIEVAL_CONFIGURATION_GUIDANCE: '96e8db7c-d972-4c47-a58f-de1921747fac',
  WAF_TESTING_GUIDANCE: '81faa43b-e6de-4b40-bc2f-15b0b3c817e1'
};

export const UNIFIED_MITIGATION_TYPE_IDS = {
  SIGMA: 1,
  YARA: 2,
  SNORT: 3
};
